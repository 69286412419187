import React, { forwardRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import withControlled from '../form/with-controlled';

import { Checkbox } from '~components/forms/checkbox';
import Autocomplete from '~components/forms/autocomplete';

import './style.scss';

const TopItems = forwardRef(({ items, value, handleChange = () => { }, isInvalid, languages }, ref) => {
  return (
    <>
      {items.map((item) => {
        return (
          item.placement === 'top' && (
            <Checkbox
              label={item.label}
              key={item.value}
              checked={value?.indexOf(item.value) >= 0}
              onChange={() => handleChange(item.value)}
              isInvalid={isInvalid}
              className="l-bordered"
              inputRef={ref}
            />
          )
        );
      })}
      {value &&
        value.map((item) => {
          return (
            languages.find((l) => l.value === item).placement !== 'top' && (
              <Checkbox
                label={languages.find((l) => l.value === item).label}
                key={item}
                checked
                onChange={() => handleChange(item)}
                className="l-bordered"
              />
            )
          );
        })}
    </>
  );
});

const OtherLanguageSelector = ({
  items,
  placeholder,
  onChange = () => { },
  onSubmit = () => { },
  isLoading = false,
  value,
  onSetValue = () => { },
  inputRef,
  name,
  isInvalid,
  isOpened,
  noMatchesMessage,
}) => {
  return (
    <Autocomplete.Container
      placeholder={placeholder}
      onChange={onChange}
      onSubmit={onSubmit}
      isLoading={isLoading}
      searchTreshold={0}
      value={value}
      setValue={onSetValue}
      inputRef={inputRef}
      name={name}
      isInvalid={isInvalid}
      initialVisible={isOpened}
      initialTouch={isOpened}
    >
      <Autocomplete.Toggle />
      <Autocomplete.Content>
        {items && items.length > 0 && (
          <>
            {items.map((s) => {
              return (
                <Autocomplete.Suggestion key={s.value} label={s.label} value={s.value} className="c-autocomplete__option">
                  {s.label}
                </Autocomplete.Suggestion>
              );
            })}
          </>
        )}
      </Autocomplete.Content>
      <Autocomplete.Empty>
        <div className="c-location__options">
          <div className="c-autocomplete__message">{noMatchesMessage}</div>
        </div>
      </Autocomplete.Empty>
    </Autocomplete.Container>
  );
};

export const LanguageSelector = ({
  languages,
  name,
  onChange = () => { },
  value,
  disabled = false,
  errors,
  isInvalid,
  inputRef,
  isOpened = false,
  isOtherEnabledDefault = false,
}, ref) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState('');
  const [topItems, setTopItems] = useState(languages.filter((item) => item.placement === 'top'));
  const [bottomItems, setBottomItems] = useState(languages.filter((item) => item.placement !== 'top'));
  const [filteredBottomItems, setFilteredBottomItems] = useState(bottomItems);
  const [isOtherEnabled, setIsOtherEnabled] = useState(isOtherEnabledDefault);

  useEffect(() => {
    if (!inputValue) {
      setFilteredBottomItems(bottomItems);
      return;
    }
    const newFilteredBottomItems = bottomItems.filter((item) => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);

    setFilteredBottomItems(newFilteredBottomItems);
  }, [inputValue]);

  useEffect(() => {
    const newTopItems = languages.filter((item) => item.placement === 'top' || value?.indexOf(item.value) >= 0);
    setTopItems(newTopItems);

    const newBottomItems = languages.filter((item) => item.placement !== 'top' && value?.indexOf(item.value) < 0);

    setBottomItems(newBottomItems);
    setFilteredBottomItems(newBottomItems);
  }, [value]);

  const handleChange = (item) => {
    let newValues = [...value];
    value.indexOf(item) < 0 ? (newValues = [...value, item]) : (newValues = newValues.filter((v) => v !== item));
    onChange(newValues);
  };

  const handleInputSubmit = (item) => {
    if (!item) {
      return;
    }
    setInputValue(null);
    handleChange(item);
  };

  return (
    <div
      className={cx('c-language-selector', {
        'c-language-selector--other-enabled': isOtherEnabled,
      })}
    >
      <div className="c-checkbox__container--horizontal">
        <TopItems items={topItems} value={value} handleChange={handleChange} isInvalid={errors && !disabled} languages={languages} ref={ref} />
      </div>

      {!isOtherEnabled ? (
        <button type="button" className="c-btn--text c-language-selector__other-button" onClick={() => setIsOtherEnabled(true)}>
          {intl.formatMessage({ id: 'components.language-selector.other-button', defaultMessage: 'Other' })}
        </button>
      ) : (
        <div className="c-language-selector__other-selector">
          <OtherLanguageSelector
            items={filteredBottomItems}
            placeholder={intl.formatMessage({ id: 'components.language-selector.placeholder', defaultMessage: 'Other language' })}
            onChange={setInputValue}
            onSubmit={handleInputSubmit}
            isLoading={false}
            value={inputValue}
            onSetValue={(v) => {
              setInputValue(v);
            }}
            inputRef={inputRef}
            name={name}
            isInvalid={isInvalid && !disabled}
            isOpened={isOpened}
            noMatchesMessage={intl.formatMessage({ id: 'components.language-selector.no-matches', defaultMessage: 'No matches' })}
          />
        </div>
      )}

      {errors && !disabled && <div className="c-input__error">{errors.message}</div>}
    </div>
  );
};

export const LanguageSelectorControlled = withControlled(forwardRef(LanguageSelector));
