import React from 'react';

import cx from 'classnames';
import { useIntl } from 'react-intl';

import './style.scss';

const CharacterCount = ({ field, max, isActive = true, className }) => {
  const intl = useIntl();

  if (!isActive) return null;
  return (
    <div
      className={cx('c-character-count', {
        [className]: !!className,
        'c-character-count--error': field && field.length > max,
      })}
    >
      {intl.formatMessage(
        {
          id: 'components.forms.character-count',
          defaultMessage: '{count}/{max} characters',
        },
        {
          count: field ? field.length : 0,
          max,
        }
      )}
    </div>
  );
};

export default CharacterCount;
