import React, { useRef } from 'react';
import cx from 'classnames';
import { FiCheck } from 'react-icons/fi';

import withControlled from '../form/with-controlled';

import './style.scss';

export const Checkbox = ({
  label,
  name = null,
  disabled,
  className,
  inputRef = useRef(),
  onChange = () => {},
  defaultChecked,
  value,
  children,
  isInvalid,
  errors,
  ...props
}) => {
  return (
    <label
      className={cx('c-checkbox', {
        [className]: !!className,
        'c-checkbox--invalid': isInvalid && !disabled,
        'c-checkbox--disabled': disabled,
        'c-checkbox--checked': props.checked || value,
      })}
    >
      <input
        type="checkbox"
        name={name}
        disabled={disabled}
        ref={inputRef?.current ? inputRef : null}
        defaultChecked={defaultChecked}
        onChange={(ev) => onChange(ev.target.checked ? value || true : false)}
        {...props}
      />
      <span className="c-checkbox__box">
        <FiCheck className="fas fa-check c-checkbox__icon" />
      </span>
      {label ? <span className="c-checkbox__label">{label}</span> : null}
      {children}
      {errors && <div className="c-input__error">{errors.message}</div>}
    </label>
  );
};

export const CheckboxControlled = withControlled(Checkbox);
