/* eslint-disable no-useless-escape */
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { isNaN } from 'lodash';

import withControlled from '../form/with-controlled';

import './style.scss';

const ENTER_KEY = 13;

export const NumericInput = ({
  step = 1,
  min = 0,
  max = 100,
  precision = 2,
  className,
  label,
  disabled = false,
  placeholder = '',
  throttle = 150,
  submitOnEnter = false,
  format = (v) => {
    return v;
  },
  onChange = () => {},
  onBlur = () => {},
  displayControls = true,
  errors,
  value,
  inputRef = useRef(),
  canFocus = true,
  isNullable = false,
  isInvalid,
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [task, setTask] = useState(0);
  const [touchPlusButtonClass, setPlusTouchButtonClass] = useState('');
  const [touchMinusButtonClass, setMinusTouchButtonClass] = useState('');

  const rgx = precision > 0 ? new RegExp(`^[0-9]*[\,\.]?[0-9]{0,${precision}}$`) : new RegExp(`^[0-9]*$`);

  const validateValue = (val) => {
    if (!val && isNullable) {
      return null;
    }

    if (val < min) {
      return min;
    }

    if (val > max) {
      return max;
    }

    if (val !== '' && isNaN(parseFloat(val))) {
      return min;
    }

    return val;
  };

  const scheduleTask = (val, delay, change) => {
    const valToValidate = parseFloat((parseFloat(val) + parseFloat(step * change)).toFixed(precision));
    const newValue = validateValue(valToValidate);
    onChange(newValue);

    setTask(setTimeout(() => scheduleTask(newValue, delay * 0.6, change), delay));
  };

  const handleChange = (ev) => {
    if (ev.target.value.match(rgx)) {
      const newValue = ev.target.value.replace(',', '.');
      onChange(newValue);
    }
  };

  const handleBlur = (ev) => {
    setIsFocused(false);
    const newValue = ev.target.value.replace(',', '.');
    const newValueValidated = validateValue(newValue);
    onChange(newValueValidated);
    onBlur(newValueValidated);
  };

  const handleFocus = (ev) => {
    if (!canFocus) {
      ev.preventDefault();
      return;
    }
    setIsFocused(true);
    setTimeout(() => inputRef.current.select(), 0);
  };

  const handleKeyDown = (ev) => {
    if (!submitOnEnter && ev.keyCode === ENTER_KEY) {
      inputRef.current.blur();
      ev.preventDefault();
    }
  };

  const increase = (ev) => {
    ev.preventDefault();
    setPlusTouchButtonClass('active-touch-plus');
    scheduleTask(value, throttle * 2, 1);
  };

  const stop = (ev) => {
    ev.preventDefault();
    setPlusTouchButtonClass('');
    setMinusTouchButtonClass('');
    onChange(value);
    clearTimeout(task);
  };

  const decrease = (ev) => {
    ev.preventDefault();
    setMinusTouchButtonClass('active-touch-minus');

    scheduleTask(value, throttle * 2, -1);
  };

  useEffect(() => {
    return () => {
      clearTimeout(task);
    };
  }, []);

  return (
    <div
      className={classNames('c-form__numeric-input', {
        'c-form__input--invalid': isInvalid,
        [className]: !!className,
        'c-form__numeric-input--focus-disabled': !canFocus,
        'c-numeric-input__no-controls': !displayControls,
      })}
    >
      {label && <div className="c-form__label">{label}</div>}

      <div className="c-numeric-input">
        {displayControls && (
          <button
            type="button"
            // className="c-numeric-input__button c-numeric-input__minus"
            className={`c-numeric-input__button c-numeric-input__minus ${touchMinusButtonClass}`}
            onMouseDown={(ev) => decrease(ev)}
            onMouseUp={(ev) => stop(ev)}
            onTouchStart={(ev) => decrease(ev)}
            onTouchEnd={(ev) => stop(ev)}
          >
            <FiMinus />
          </button>
        )}
        <input
          ref={inputRef}
          className={classNames('c-numeric-input__input')}
          value={isFocused ? value : format(value)}
          placeholder={placeholder}
          disabled={!canFocus || disabled}
          onChange={(ev) => {
            handleChange(ev);
          }}
          onFocus={(ev) => handleFocus(ev)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        {displayControls && (
          <button
            type="button"
            // className="c-numeric-input__button c-numeric-input__plus"
            className={`c-numeric-input__button c-numeric-input__plus ${touchPlusButtonClass}`}
            onMouseDown={(ev) => increase(ev)}
            onMouseUp={(ev) => stop(ev)}
            onTouchStart={(ev) => increase(ev)}
            onTouchEnd={(ev) => stop(ev)}
          >
            <FiPlus />
          </button>
        )}
      </div>

      {errors && !disabled && <div className="c-input__error">{errors.message}</div>}
    </div>
  );
};

export const NumericInputControlled = withControlled(forwardRef(NumericInput));
