import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { throttle } from 'lodash';

import withControlled from '../form/with-controlled';

import './style.scss';

let preTagPortal;
const ENTER_KEY = 13;

export const Textarea = ({
  className,
  isDisabled = false,
  isAutoExpand = false,
  submitOnEnter = false,
  showErrors = true,
  style = {},
  name,
  value,
  placeholder,
  text,
  errors,
  isInvalid,
  disabled,
  onKeyDown = () => {},
  onPaste = () => {},
  onChange = () => {},
  onSubmit = () => {},
  inputRef = useRef(),
  ...props
}) => {
  if (!preTagPortal && isAutoExpand && typeof window !== 'undefined') {
    preTagPortal = document.createElement('div');
    document.body.appendChild(preTagPortal);
  }

  const [height, setHeight] = useState();

  const measuredRef = useCallback(
    (node) => {
      if (node !== null && isAutoExpand && typeof window !== 'undefined') {
        setHeight(node.getBoundingClientRect().height);
      }
    },
    [value]
  );

  useEffect(() => {
    return () => {
      if (preTagPortal && isAutoExpand && typeof window !== 'undefined') {
        document.body.removeChild(preTagPortal);
        preTagPortal = null;
      }
    };
  }, [isAutoExpand]);

  const handleChange = throttle((evt) => {
    inputRef.current && onChange(inputRef.current.value);
    onKeyDown(evt);
  }, 33);

  const renderPortal = () => {
    return ReactDOM.createPortal(
      <div className="c-textarea__pre" style={{ width: inputRef.current.clientWidth }} ref={measuredRef}>
        {value}
        <br />
      </div>,
      preTagPortal
    );
  };

  const handleKeyDown = (evt) => {
    if (submitOnEnter && evt.keyCode === ENTER_KEY && !evt.shiftKey) {
      evt.preventDefault();
      onSubmit();
    }
  };

  return (
    <>
      <textarea
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={onPaste}
        disabled={isDisabled}
        name={name}
        className={cx('c-textarea', {
          'c-textarea--auto-expand': isAutoExpand,
          'c-textarea--invalid': isInvalid && !disabled,
          [className]: !!className,
        })}
        style={isAutoExpand ? { ...style, height } : style}
        wrap="soft"
        placeholder={placeholder}
        ref={inputRef}
        value={value}
        spellCheck="true"
        {...props}
      />
      {inputRef.current && isAutoExpand && typeof window !== 'undefined' ? renderPortal() : null}
      {errors && showErrors && !disabled && (
        <div
          className={cx('c-input__error', {
            'c-textarea__error--relative ': isAutoExpand,
          })}
        >
          {errors.message}
        </div>
      )}
    </>
  );
};

export const TextareaControlled = withControlled(Textarea);
